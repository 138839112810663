@font-face {
font-family: '__MomentsDisplayBG_10e9d1';
src: url(/_next/static/media/5b277404671d584f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: '__MomentsDisplayBG_10e9d1';
src: url(/_next/static/media/92169a77ae7021e6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: '__MomentsDisplayBG_10e9d1';
src: url(/_next/static/media/5d14a443359326f1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__MomentsDisplayBG_Fallback_10e9d1';src: local("Arial");ascent-override: 115.99%;descent-override: 29.97%;line-gap-override: 0.00%;size-adjust: 82.07%
}.__className_10e9d1 {font-family: '__MomentsDisplayBG_10e9d1', '__MomentsDisplayBG_Fallback_10e9d1'
}.__variable_10e9d1 {--font-oetker-moments-display: '__MomentsDisplayBG_10e9d1', '__MomentsDisplayBG_Fallback_10e9d1'
}

@font-face {
font-family: '__MomentsTextBG_16b3bf';
src: url(/_next/static/media/17bfe8cf53a3d4b9-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__MomentsTextBG_Fallback_16b3bf';src: local("Arial");ascent-override: 95.64%;descent-override: 24.44%;line-gap-override: 0.00%;size-adjust: 99.44%
}.__className_16b3bf {font-family: '__MomentsTextBG_16b3bf', '__MomentsTextBG_Fallback_16b3bf'
}.__variable_16b3bf {--font-oetker-moments-text: '__MomentsTextBG_16b3bf', '__MomentsTextBG_Fallback_16b3bf'
}

@font-face {
font-family: '__MomentsDisplayTR_41d2b8';
src: url(/_next/static/media/035109d8c99ef9d7-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: '__MomentsDisplayTR_41d2b8';
src: url(/_next/static/media/637bb93d823180d3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: '__MomentsDisplayTR_41d2b8';
src: url(/_next/static/media/682e40a3a4e6cbbe-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__MomentsDisplayTR_Fallback_41d2b8';src: local("Arial");ascent-override: 115.99%;descent-override: 29.97%;line-gap-override: 0.00%;size-adjust: 82.07%
}.__className_41d2b8 {font-family: '__MomentsDisplayTR_41d2b8', '__MomentsDisplayTR_Fallback_41d2b8'
}.__variable_41d2b8 {--font-oetker-moments-display: '__MomentsDisplayTR_41d2b8', '__MomentsDisplayTR_Fallback_41d2b8'
}

@font-face {
font-family: '__MomentsTextTR_30ab5d';
src: url(/_next/static/media/5d4953858d636d04-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__MomentsTextTR_Fallback_30ab5d';src: local("Arial");ascent-override: 95.64%;descent-override: 24.44%;line-gap-override: 0.00%;size-adjust: 99.44%
}.__className_30ab5d {font-family: '__MomentsTextTR_30ab5d', '__MomentsTextTR_Fallback_30ab5d'
}.__variable_30ab5d {--font-oetker-moments-text: '__MomentsTextTR_30ab5d', '__MomentsTextTR_Fallback_30ab5d'
}

@font-face {
font-family: '__Ceramika_393df4';
src: url(/_next/static/media/a76bcb7d27569ff5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__Ceramika_Fallback_393df4';src: local("Arial");ascent-override: 105.78%;descent-override: 22.59%;line-gap-override: 0.00%;size-adjust: 97.37%
}.__className_393df4 {font-family: '__Ceramika_393df4', '__Ceramika_Fallback_393df4';font-weight: 400
}.__variable_393df4 {--font-ceramika: '__Ceramika_393df4', '__Ceramika_Fallback_393df4'
}

@font-face {
font-family: '__FuturaPT_f8b59c';
src: url(/_next/static/media/ce8875d226ed4827-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}@font-face {font-family: '__FuturaPT_Fallback_f8b59c';src: local("Arial");ascent-override: 108.56%;descent-override: 33.17%;line-gap-override: 0.00%;size-adjust: 90.45%
}.__className_f8b59c {font-family: '__FuturaPT_f8b59c', '__FuturaPT_Fallback_f8b59c';font-weight: 500
}.__variable_f8b59c {--font-futura-pt: '__FuturaPT_f8b59c', '__FuturaPT_Fallback_f8b59c'
}

@font-face {
font-family: '__AugustScript_d2e1ed';
src: url(/_next/static/media/a2e99cde1fb15c40-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__AugustScript_Fallback_d2e1ed';src: local("Arial");ascent-override: 118.23%;descent-override: 48.09%;line-gap-override: 0.00%;size-adjust: 82.97%
}.__className_d2e1ed {font-family: '__AugustScript_d2e1ed', '__AugustScript_Fallback_d2e1ed'
}.__variable_d2e1ed {--font-oetker-august-script: '__AugustScript_d2e1ed', '__AugustScript_Fallback_d2e1ed'
}

@font-face {
font-family: '__MomentsDisplay_a73c4e';
src: url(/_next/static/media/6c0a6a60376c4ea4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: '__MomentsDisplay_a73c4e';
src: url(/_next/static/media/6ef8a300e8fd6239-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: '__MomentsDisplay_a73c4e';
src: url(/_next/static/media/10facfa8edca2ef9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__MomentsDisplay_Fallback_a73c4e';src: local("Arial");ascent-override: 115.99%;descent-override: 29.97%;line-gap-override: 0.00%;size-adjust: 82.07%
}.__className_a73c4e {font-family: '__MomentsDisplay_a73c4e', '__MomentsDisplay_Fallback_a73c4e'
}.__variable_a73c4e {--font-oetker-moments-display: '__MomentsDisplay_a73c4e', '__MomentsDisplay_Fallback_a73c4e'
}

@font-face {
font-family: '__MomentsText_0a3ea5';
src: url(/_next/static/media/b3454eed89952848-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__MomentsText_Fallback_0a3ea5';src: local("Arial");ascent-override: 95.64%;descent-override: 24.44%;line-gap-override: 0.00%;size-adjust: 99.44%
}.__className_0a3ea5 {font-family: '__MomentsText_0a3ea5', '__MomentsText_Fallback_0a3ea5'
}.__variable_0a3ea5 {--font-oetker-moments-text: '__MomentsText_0a3ea5', '__MomentsText_Fallback_0a3ea5'
}

